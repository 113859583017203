import { put, call } from 'redux-saga/effects'
import * as action_type from './Constant';
import { AUTH_HEADERS, API_URL, AUTH_MULTYPART_HEADERS } from '../Axios/axiosData'
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';



export function* commonRequestSaga(payload) {

    let data = { response: false, backend_data: null }
    yield put({ type: action_type.SET_LOADING, payload: true })

    yield axios.post(API_URL + "accounts/user_connections/", payload?.payload, AUTH_HEADERS())
        .then(resp => {
            if (resp?.data?.success) {
                data.response = true
                data.backend_data = resp.data.data
                toast.success("Request sent successfully")
            }
            else {
                toast.error(resp?.data?.message)
            }

        }).catch((error) => {
            console.log('error', error);
            toast.error(error?.response?.data?.message)
        })

    yield put({ type: action_type.SET_COMMON_REQ, data })
    yield put({ type: action_type.SET_LOADING, payload: false })

}


export function* getAllNotificationSaga(payload) {
    let data = { response: false, backend_data: null };
    yield put({ type: action_type.SET_LOADING, payload: true });
    const header = AUTH_HEADERS();


    try {
        const response = yield call(fetch, API_URL + "common/notification/", {
            method: 'GET',
            headers: header.headers,
            body: JSON.stringify(payload?.payload)
        });
        const resp = yield response.json();

        if (resp.length) {
            data.response = true;
            data.backend_data = resp;
        } else {
        }
    } catch (error) {
        // toast.error(`Network Error`);
    }

    yield put({ type: action_type.SET_NOTIFICATION_REQ, data });
    yield put({ type: action_type.SET_LOADING, payload: false });
}

export function* updateNotificationSaga(payload) {

    let data = { response: false, backend_data: null }
    yield put({ type: action_type.SET_LOADING, payload: true })
    yield axios.patch(API_URL + "common/notification/" + payload?.payload.id + "/", payload?.payload, AUTH_HEADERS())
        .then(resp => {
            if (resp?.data?.success) {
                data.response = true
                data.backend_data = resp.data.data
                toast.success("Request sent successfully")
            }
            else {
                toast.error(resp?.data?.message)
            }

        }).catch((error) => {
        })

    yield put({ type: action_type.SET_UPDATE_NOTIFICATION_REQ, data })
    yield put({ type: action_type.SET_LOADING, payload: false })

}
export function* accepRejectConnectReqSaga(payload) {

    let data = { response: false, backend_data: null }
    yield put({ type: action_type.SET_LOADING, payload: true })

    yield axios.patch(API_URL + "accounts/user_connections/" + payload?.payload.id + "/", payload?.payload, AUTH_HEADERS())
        .then(resp => {
            if (resp?.data?.success) {
                data.response = true
                data.backend_data = resp.data.data
            }
            else {
                // toast.error(resp?.data?.message)
            }

        }).catch((error) => {
            toast.error(`Net Work Error`)
        })

    yield put({ type: action_type.SET_UPDATE_NOTIFICATION_REQ, data })
    yield put({ type: action_type.SET_LOADING, payload: false })

}
export function* deleteNotificationReqSaga(payload) {

    let data = { response: false, backend_data: null }
    yield put({ type: action_type.SET_LOADING, payload: true })

    yield axios.patch(API_URL + "common/notification/" + payload?.payload.id + "/", payload?.payload, AUTH_HEADERS())
        .then(resp => {
            if (resp?.data?.success) {
                data.response = true
                data.backend_data = resp.data.data
                toast.success("Request sent successfully")
            }
            else {
                toast.error(resp?.data?.message)
            }

        }).catch((error) => {
            toast.error(`Net Work Error`)
        })

    yield put({ type: action_type.SET_UPDATE_NOTIFICATION_REQ, data })
    yield put({ type: action_type.SET_LOADING, payload: false })

}
export function* getAllPlanSaga(payload) {
    let data = { response: false, backend_data: null }
    yield put({ type: action_type.SET_LOADING, payload: true })

    yield axios.get(API_URL + "subscription/subscription_plans/", AUTH_HEADERS())
        .then(resp => {
            if (resp?.status === 200) {
                data.response = true
                data.backend_data = resp.data
            }
            else {
            }
        }).catch((error) => {
            // toast.error(`Net Work Error`)
        })

    yield put({ type: action_type.SET_ALL_PLANS, data })
    yield put({ type: action_type.SET_LOADING, payload: false })

}
export function* purchasePlanSaga(payload) {
    let data = { response: false, backend_data: null }
    yield put({ type: action_type.SET_LOADING, payload: true })

    yield axios.post(API_URL + "subscription/initiate_payment/", payload.payload, AUTH_HEADERS())
        .then(resp => {
            if (resp?.data) {
                data.response = true
                data.backend_data = resp.data
                payload.callback(resp.data)
            }
            else {
                toast.error(resp?.data?.message)
            }

        }).catch((error) => {
            // toast.error(`Net Work Error`)
        })

    yield put({ type: action_type.SET_PURCHASE_PLAN, data })
    yield put({ type: action_type.SET_LOADING, payload: false })

}
export function* userCurrentPlanSaga(payload) {

    let data = { response: false, backend_data: null }
    yield put({ type: action_type.SET_LOADING, payload: true })

    yield axios.get(API_URL + "subscription/plan-details/", AUTH_HEADERS())
        .then(resp => {
            if (resp?.data) {
                data.response = true
                data.backend_data = resp.data
            }
            else {
            }

        }).catch((error) => {
        })

    yield put({ type: action_type.SET_CURRENT_PLAN, data })
    yield put({ type: action_type.SET_LOADING, payload: false })

}

export function* cancelledPlanSaga(payload) {
    let data = { response: false, backend_data: null }
    yield put({ type: action_type.SET_LOADING, payload: true })
  

    yield axios.post(API_URL + "subscription/cancel_subscription/", payload.payload, AUTH_HEADERS())
        .then(resp => {
            if (resp?.status === 200) {
                data.response = true
                data.backend_data = resp.data
                payload.callback(resp.data)
                toast.success(resp.data.details);
            }
            else {
                payload.callback(resp.data)
            }

        }).catch((error) => {
         
            payload.callback(error.response.data)
           
            

        })

    yield put({ type: action_type.SET_CANCEL_PLAN, data })
    yield put({ type: action_type.SET_LOADING, payload: false })

}

export function* saveAiTemplateSaga(payload) {
    let data = { response: false, backend_data: null };
    yield put({ type: action_type.SET_LOADING, payload: true });

    let resp = null;

    try {
        resp = yield axios.post(API_URL + "ai/draft-template/", payload.payload, AUTH_HEADERS());
        if (resp?.data) {
            data.response = true;
            data.backend_data = resp.data;
            payload.callback(resp);
        } else {
            toast.error(resp?.data?.message);
        }
    } catch (error) {

    }

    yield put({ type: action_type.SET_SAVE_AI_TEMPLATE, data });
    yield put({ type: action_type.SET_LOADING, payload: false });


}

export function* updateAiTemplateSaga(action) {
    let data = { response: false, backend_data: null };
    yield put({ type: action_type.SET_LOADING, payload: true });

    try {
        // First API call: Update the AI template
        const response = yield call(
            axios.patch,
            `${API_URL}ai/draft-template/${action.payload.id}/`,
            action.payload,
            AUTH_HEADERS()
        );

        if (response?.data) {
            data.response = true;
            data.backend_data = response.data;
            if (action.callback) action.callback(response.data);
        } else {
        }

        // Second API call: Get updated templates
        yield call(
            axios.get,
            `${API_URL}ai/draft-template/${action.payload.id}/update-templates/`,
            AUTH_HEADERS()
        );

        yield put({ type: action_type.SET_UPDATE_AI_TEMPLATE, payload: data });

    } catch (error) {
        console.error(error);
    } finally {
        // Ensure that loading state is turned off
        yield put({ type: action_type.SET_LOADING, payload: false });
    }
}

export function* downloadPdfAiTemplateSaga(payload) {
    let data = { response: false, backend_data: null };
    yield put({ type: action_type.SET_LOADING, payload: true });

    let resp = null;

    try {
        resp = yield axios.get(API_URL + `ai/draft-template/${payload.id}/download_pdf/`, AUTH_HEADERS());
        if (resp?.data) {
            data.response = true;
            data.backend_data = resp.data;
            payload.callback({ type: 'pdf', res: resp });
        } else {
            toast.error(resp?.data?.message);
        }
    } catch (error) {
    }

    yield put({ type: action_type.SET_DOWNLOAD_AI_TEMPLATE_PDF, data });
    yield put({ type: action_type.SET_LOADING, payload: false });

}
export function* downloadDocxAiTemplateSaga(payload) {
    let data = { response: false, backend_data: null };
    yield put({ type: action_type.SET_LOADING, payload: true });

    let resp = null;

    try {
        resp = yield axios.get(API_URL + `ai/draft-template/${payload.id}/download_docx/`, AUTH_HEADERS());
        if (resp?.data) {
            data.response = true;
            data.backend_data = resp.data;
            payload.callback({ type: 'docx', res: resp });
        } else {
            toast.error(resp?.data?.message);
        }
    } catch (error) {
    }

    yield put({ type: action_type.SET_DOWNLOAD_AI_TEMPLATE_DOCX, data });
    yield put({ type: action_type.SET_LOADING, payload: false });

}


// esigning
export function* sendeSignDocsSaga(payload) {
    let data = { response: false, backend_data: null };
    yield put({ type: action_type.SET_LOADING, payload: true });

    let resp = null;

    try {
        resp = yield axios.post(API_URL + `esign/document/`, payload.data, AUTH_HEADERS());
        if (resp?.data) {
            data.response = true;
            data.backend_data = resp.data;
            payload.callback(resp);
        } else {
            toast.error(resp?.data?.message);
        }
    } catch (error) {
    }

    yield put({ type: action_type.SET_SEND_ESIGN_DOCS, data });
    yield put({ type: action_type.SET_LOADING, payload: false });


}
export function* getAlleSignDocsSaga(payload) {
    let data = { response: false, backend_data: null };
    yield put({ type: action_type.SET_LOADING, payload: true });

    let resp = null;

    try {
        resp = yield axios.get(API_URL + `esign/document/`, AUTH_HEADERS());
        if (resp?.data) {
            data.response = true;
            data.backend_data = resp.data;
            payload.callback(resp);
        } else {
            toast.error(resp?.data?.message);
        }
    } catch (error) {
    }

    yield put({ type: action_type.SET_ALL_ESIGN_DOCS, data });
    yield put({ type: action_type.SET_LOADING, payload: false });


}
export function* updateESignDocsSaga(payload) {

    let data = { response: false, backend_data: null };
    yield put({ type: action_type.SET_LOADING, payload: true });

    let resp = null;
    try {
        resp = yield axios.patch(API_URL + `esign/document/` + payload.data.id + '/', payload.data.file, AUTH_MULTYPART_HEADERS());
        if (resp?.data) {
            data.response = true;
            data.backend_data = resp.data;
            payload.callback(resp);
        } else {
            toast.error(resp?.data?.message);
        }
    } catch (error) {
    }

    yield put({ type: action_type.SET_UPDATE_ESIGN_DOCS, data });
    yield put({ type: action_type.SET_LOADING, payload: false });

}
export function* deleteESignDocsSaga(payload) {

    let data = { response: false, backend_data: null };
    yield put({ type: action_type.SET_LOADING, payload: true });

    let resp = null;
    try {
        resp = yield axios.delete(API_URL + `esign/document/` + payload.id + '/', AUTH_HEADERS());
        if (resp?.status == 204) {
            payload.callback(true)
        } else {
            toast.error(resp?.data?.message);
        }
    } catch (error) {
    }
    yield put({ type: action_type.SET_DELTEE_ESIGN_DOCS, data });
    yield put({ type: action_type.SET_LOADING, payload: false });

}
export function* getAllInvoiceSaga(payload) {

    let data = { response: false, backend_data: null };
    yield put({ type: action_type.SET_LOADING, payload: true });
    try {
        let resp = yield axios.get(API_URL + payload.url , AUTH_HEADERS());
        
        if (resp?.status == 200) {
            data.backend_data = resp.data;
            payload.callback(true)
        } else {
            toast.error(resp?.data?.message);
        }
    } catch (error) {
    }
    yield put({ type: action_type.SET_INVOICES, data });
    yield put({ type: action_type.SET_LOADING, payload: false });

}
export function* getCreateInvoiceSaga(payload) {

    let data = { response: false, backend_data: null };
    yield put({ type: action_type.SET_LOADING, payload: true });

 
    try {
        let resp = yield axios.post(API_URL + `invoice/standalone-invoices/`,payload.payload, AUTH_MULTYPART_HEADERS());
        console.log('resp',resp);
        if (resp?.status == 201) {
            data.backend_data = resp.data;
            payload.callBack(resp);
            
        } else {
            toast.error(resp?.data?.message);
            payload.callBack(resp);
        }
    } catch (error) {
        payload.callBack(error);
    }
    yield put({ type: action_type.SET_CREATE_INVOICES, data });
    yield put({ type: action_type.SET_LOADING, payload: false });
}




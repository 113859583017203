import * as action_type from '../../../../Store/Constant'

export const jobsfilterDataReducer = (data = {response: false, backend_data: null }, action) => {
    switch (action.type) {
        case action_type.SET_JOBS_FILTERS_DATA:
            return action.data;
        default:
            return data
    }
}

export const latestJobsDataReducer = (data = {response: false, backend_data: null }, action) => {
    switch (action.type) {
        case action_type.SET_LATEST_JOBS_DATA:
            return action.data;
        default:
            return data
    }
} 

export const profilesfilterDataReducer = (data = {response: false, backend_data: null }, action) => {
    switch (action.type) {
        case action_type.SET_PROFILES_FILTERS_DATA:
            return action.data;
        default:
            return data
    }
}
import * as action_type from '../../../../Store/Constant'

export const workAvailabilityReducer = (data = { response: false, backend_data: null }, action) => {
    switch (action.type) {
        case action_type.SET_WORK_AVAILABILITY:
            return action.data;
        default:
            return data
    }
}

export const userProfileDetailsReducer = (data = { response: false, backend_data: null }, action) => {
    switch (action.type) {
        case action_type.SET_USER_PROFILE_DETAILS_DATA:
            return action.data;
        default:
            return data
    }
}
import { put } from 'redux-saga/effects'
import * as action_type from '../../../../Store/Constant';
import { AUTH_MULTYPART_HEADERS, API_URL, AUTH_HEADERS, LIMIT_DATA } from '../../../../Axios/axiosData'
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';

export function* getMsaApprovalListData(payload) {

    let data = { response: false, backend_data: null }
    yield put({ type: action_type.SET_LOADING, payload: true })

    yield axios.get(API_URL + `msa/master-agreement/?${payload?.payload}&status=pending_approval`, AUTH_MULTYPART_HEADERS())
        .then(resp => {
            data.response = true
            data.backend_data = resp.data

        }).catch((error) => {
            console.log(error, "error")
        })

    yield put({ type: action_type.SET_LOADING, payload: false })
    yield put({ type: action_type.SET_MSA_APPROVAL_LIST, data })

}

export function* getSowApprovalListData(payload) {

    let data = { response: false, backend_data: null }
    yield put({ type: action_type.SET_LOADING, payload: true })

    yield axios.get(API_URL + `sow/statement-of-work/?${payload?.payload}&status=pending_approval`, AUTH_MULTYPART_HEADERS())
        .then(resp => {
            data.response = true
            data.backend_data = resp.data

        }).catch((error) => {
            console.log(error, "error")
        })

    yield put({ type: action_type.SET_LOADING, payload: false })
    yield put({ type: action_type.SET_SOW_APPROVAL_LIST, data })

}

export function* getTimesheetApprovalListData(payload) {
    let data = { response: false, backend_data: null }
    yield put({ type: action_type.SET_LOADING, payload: true })

    yield axios.get(API_URL + `timesheet/timesheet/?${payload?.payload}&status=1`, AUTH_MULTYPART_HEADERS())
        .then(resp => {
            data.response = true
            data.backend_data = resp.data

        }).catch((error) => {
            console.log(error, "error")
        })

    yield put({ type: action_type.SET_LOADING, payload: false })
    yield put({ type: action_type.SET_TIMESHEET_APPROVAL_LIST, data })
}

export function* getInvoiceApprovalListData(payload) {
    let data = { response: false, backend_data: null }
    yield put({ type: action_type.SET_LOADING, payload: true })

    yield axios.get(API_URL + `invoice/invoice/?${payload?.payload}&status=Pending`, AUTH_MULTYPART_HEADERS())
        .then(resp => {
            data.response = true
            data.backend_data = resp.data

        }).catch((error) => {
            console.log(error, "error")
        })

    yield put({ type: action_type.SET_LOADING, payload: false })
    yield put({ type: action_type.SET_INVOICE_APPROVAL_LIST, data })
}

export function* getCallApproveStatus(payload) {

    let slugName = payload?.payload?.slug
    let status = payload?.payload?.status

    let data = { response: false, backend_data: null }
    yield put({ type: action_type.SET_LOADING, payload: true })

    yield axios.patch(API_URL + `sow/statement-of-work/${slugName}/`, payload.payload, AUTH_HEADERS())
        .then(resp => {
            data.response = true
            data.backend_data = resp.data

            if(payload?.payload?.status == "approved"){
                toast.success("Approved Successfully")
            }
            if(payload?.payload?.status == "rejected"){
                toast.success("Rejected Successfully")
            }

        }).catch((error) => {
            toast.error("Error !!")
        })

    yield axios.get(API_URL + `sow/statement-of-work/?limit=${LIMIT_DATA}&status=pending_approval`, AUTH_MULTYPART_HEADERS())
        .then(resp => {
            data.response = true
            data.backend_data = resp.data

        }).catch((error) => {
            console.log(error, "error")
        })

    yield put({ type: action_type.SET_LOADING, payload: false })
    yield put({ type: action_type.SET_SOW_APPROVAL_LIST, data })

}

export function* getMsaCallApproveStatus(payload) {

    let slugName = payload?.payload?.slug
    let status = payload?.payload?.status

    let data = { response: false, backend_data: null }
    yield put({ type: action_type.SET_LOADING, payload: true })

    yield axios.patch(API_URL + `msa/master-agreement/${slugName}/`, payload.payload, AUTH_HEADERS())
        .then(resp => {
            data.response = true
            data.backend_data = resp.data

            if(payload?.payload?.status == "approved"){
                toast.success("Approved Successfully")
            }
            if(payload?.payload?.status == "rejected"){
                toast.success("Rejected Successfully")
            }

        }).catch((error) => {
            toast.error("Error !!")
        })

    yield axios.get(API_URL + `msa/master-agreement/?limit=${LIMIT_DATA}&status=pending_approval`, AUTH_MULTYPART_HEADERS())
        .then(resp => {
            data.response = true
            data.backend_data = resp.data

        }).catch((error) => {
            toast.error("Error !!")
        })

    yield put({ type: action_type.SET_LOADING, payload: false })
    yield put({ type: action_type.SET_MSA_APPROVAL_LIST, data })

}

export function* getTimesheetCallApproveStatus(payload) {

    let data = { response: false, backend_data: null }
    yield put({ type: action_type.SET_LOADING, payload: true })

    yield axios.patch(API_URL + `timesheet/timesheet/${payload?.payload?.slug}/`, payload.payload , AUTH_HEADERS())
        .then(resp => {
            data.response = true
            data.backend_data = resp.data

            if(payload?.payload?.status == "1"){
                toast.success("Pending Successfully")
            }
            if(payload?.payload?.status == "2"){
                toast.success("Approved Successfully")
            }
            if(payload?.payload?.status == "3"){
                toast.success("Rejected Successfully")
            }

        }).catch((error) => {
            toast.error("Error !!")
        })

    yield axios.get(API_URL + `timesheet/timesheet/?limit=${LIMIT_DATA}&status=1`, AUTH_MULTYPART_HEADERS())
        .then(resp => {
            data.response = true
            data.backend_data = resp.data

        }).catch((error) => {
            console.log(error, "error")
        })

    yield put({ type: action_type.SET_LOADING, payload: false })
    yield put({ type: action_type.SET_TIMESHEET_APPROVAL_LIST, data })

}

export function* getInvoiceCallApproveStatus(payload) {

    let data = { response: false, backend_data: null }
    yield put({ type: action_type.SET_LOADING, payload: true })

    yield axios.patch(API_URL + `invoice/invoice/${payload?.payload?.slug}/`, payload.payload , AUTH_HEADERS())
        .then(resp => {
            data.response = true
            data.backend_data = resp.data

            if(payload?.payload?.status == "Pending"){
                toast.success("Pending Successfully")
            }
            if(payload?.payload?.status == "Accepted"){
                toast.success("Approved Successfully")
            }
            if(payload?.payload?.status == "Rejected"){
                toast.success("Rejected Successfully")
            }

        }).catch((error) => {
            toast.error("Error !!")
        })

    yield axios.get(API_URL + `invoice/invoice/?limit=${LIMIT_DATA}&status=Pending`, AUTH_MULTYPART_HEADERS())
        .then(resp => {
            data.response = true
            data.backend_data = resp.data

        }).catch((error) => {
            console.log(error, "error")
        })

    yield put({ type: action_type.SET_LOADING, payload: false })
    yield put({ type: action_type.SET_INVOICE_APPROVAL_LIST, data })

}
import { put } from 'redux-saga/effects'
import * as action_type from '../../../../Store/Constant';
import { AUTH_MULTYPART_HEADERS, API_URL, AUTH_HEADERS, HEADERS, LIMIT_DATA } from '../../../../Axios/axiosData'
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';

export function* getOrganisationUsersList(payload) {

    let data = { response: false, backend_data: null }
    yield put({ type: action_type.SET_LOADING, payload: true })

    let url = ``

    if (payload?.payload) {
        url = API_URL + `organisation/organisation-user/?limit=${LIMIT_DATA}${payload?.payload}`
    } else {
        url = API_URL + `organisation/organisation-user/?limit=500`
    }

    yield axios.get(url, AUTH_MULTYPART_HEADERS())
        .then(resp => {
            data.response = true
            data.backend_data = resp.data

        }).catch((error) => {
            console.log(error, "error")
        })

    yield put({ type: action_type.SET_LOADING, payload: false })
    yield put({ type: action_type.SET_ORGANISATION_USERS_LIST, data })

}

export function* deleteOrganisationUserData(payload) {

    let data = { response: false, backend_data: null }

    yield axios.delete(API_URL + `organisation/organisation-user/${payload?.payload}/`, AUTH_HEADERS())
        .then(resp => {
            data.response = true
            data.backend_data = resp.data

            toast.success("User Successfully Deleted")

        }).catch(error => {

            if (error?.response?.data?.email) {
                toast.error(`${"Email"} : ${error?.response?.data?.email[0]}`)
            }

            if (error?.response?.data?.user) {
                toast.error(`${"User"} : ${error?.response?.data?.user}`)
            }

            if (error?.response?.data?.detail) {
                toast.error(`${error?.response?.data?.detail}`)
            }

        })

    yield axios.get(API_URL + `organisation/organisation-user/?limit=${LIMIT_DATA}`, AUTH_MULTYPART_HEADERS())
        .then(resp => {
            data.response = true
            data.backend_data = resp.data

        }).catch((error) => {
            toast.error(error.message)
        })

    yield put({ type: action_type.SET_ORGANISATION_USERS_LIST, data })
    yield put({ type: action_type.GET_NAVIGATE, data })

}

export function* getAddOrganisationUsers({ payload }) {

    let data = { response: false, backend_data: null }
    let formValue = payload

    yield axios.post(API_URL + "organisation/organisation-user/", formValue, AUTH_MULTYPART_HEADERS())
        .then(resp => {
            data.response = true
            data.backend_data = resp.data

            toast.success("User Create Successfully")

        }).catch(error => {

            if (error?.response?.data?.email) {
                toast.error(`${"Email"} : ${error?.response?.data?.email[0]}`)
            }

            if (error?.response?.data?.user) {
                toast.error(`${"User"} : ${error?.response?.data?.user}`)
            }

        })

    yield axios.get(API_URL + `organisation/organisation-user/?limit=${LIMIT_DATA}`, AUTH_MULTYPART_HEADERS())
        .then(resp => {
            data.response = true
            data.backend_data = resp.data

        }).catch((error) => {
            toast.error(error.message)
        })

    yield put({ type: action_type.SET_ORGANISATION_USERS_LIST, data })
    yield put({ type: action_type.GET_NAVIGATE, data })
}

export function* getAgencyUsersList(payload) {

    let data = { response: false, backend_data: null }
    yield put({ type: action_type.SET_LOADING, payload: true })

    let url ;
    if (payload?.payload) {
        url = API_URL + `agency/agency-user/?limit=${LIMIT_DATA}${payload?.payload}`
    } else {
        url = API_URL + `agency/agency-user/?limit==500`
    }

    yield axios.get(url, AUTH_MULTYPART_HEADERS())
        .then(resp => {
            data.response = true
            data.backend_data = resp.data

        }).catch((error) => {
            console.log(error, "error")
        })

    yield put({ type: action_type.SET_LOADING, payload: false })
    yield put({ type: action_type.SET_AGENCY_USERS_LIST, data })

}

export function* getAddAgencyUsers({ payload }) {

    let data = { response: false, backend_data: null }
    let formValue = payload

    yield axios.post(API_URL + "agency/agency-user/", formValue, AUTH_MULTYPART_HEADERS())
        .then(resp => {
            data.response = true
            data.backend_data = resp.data

            toast.success("User Create Successfully")

        }).catch(error => {

            if (error?.response?.data?.email) {
                toast.error(`${"Email"} : ${error?.response?.data?.email[0]}`)
            }

            if (error?.response?.data?.user) {
                toast.error(`${"User"} : ${error?.response?.data?.user}`)
            }

        })

    yield axios.get(API_URL + `agency/agency-user/?limit=${LIMIT_DATA}`, AUTH_MULTYPART_HEADERS())
        .then(resp => {
            data.response = true
            data.backend_data = resp.data

        }).catch((error) => {
            toast.error(error.message)
        })

    yield put({ type: action_type.SET_AGENCY_USERS_LIST, data })
    yield put({ type: action_type.GET_NAVIGATE, data })
}

export function* deleteAgencyUserData(payload) {

    let data = { response: false, backend_data: null }

    yield axios.delete(API_URL + `agency/agency-user/${payload?.payload}/`, AUTH_HEADERS())
        .then(resp => {
            data.response = true
            data.backend_data = resp.data

            toast.success("User Successfully Deleted")

        }).catch(error => {

            if (error?.response?.data?.email) {
                toast.error(`${"Email"} : ${error?.response?.data?.email[0]}`)
            }

            if (error?.response?.data?.user) {
                toast.error(`${"User"} : ${error?.response?.data?.user}`)
            }

            if (error?.response?.data?.detail) {
                toast.error(`${error?.response?.data?.detail}`)
            }

        })

    yield axios.get(API_URL + `agency/agency-user/?limit=${LIMIT_DATA}`, AUTH_MULTYPART_HEADERS())
        .then(resp => {
            data.response = true
            data.backend_data = resp.data

        }).catch((error) => {
            toast.error(error.message)
        })

    yield put({ type: action_type.SET_AGENCY_USERS_LIST, data })
    yield put({ type: action_type.GET_NAVIGATE, data })

}

export function* getOrgUserDetailData(payload) {

    let data = { response: false, backend_data: null }
    yield put({ type: action_type.SET_LOADING, payload: true })

    yield axios.get(API_URL + `organisation/organisation-user/${payload.payload}/`, AUTH_HEADERS())
        .then(resp => {
            data.response = true
            data.backend_data = resp.data

        }).catch((error) => {
            console.log(error, "error")
        })

    yield put({ type: action_type.SET_LOADING, payload: false })
    yield put({ type: action_type.SET_ORG_USER_DETAILS_DATA, data })

}

export function* getAgnUserDetailData(payload) {

    let data = { response: false, backend_data: null }
    yield put({ type: action_type.SET_LOADING, payload: true })

    yield axios.get(API_URL + `agency/agency-user/${payload.payload}/`, AUTH_HEADERS())
        .then(resp => {

            data.response = true
            data.backend_data = resp.data

        }).catch((error) => {
            console.log(error, "error")
        })

    yield put({ type: action_type.SET_LOADING, payload: false })
    yield put({ type: action_type.SET_AGN_USER_DETAILS_DATA, data })

}

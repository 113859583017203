import * as action_type from '../../../../Store/Constant'

export const msaApprovalReducer = (data = {response: false, backend_data: null }, action) => {
    switch (action.type) {
        case action_type.SET_MSA_APPROVAL_LIST:
            return action.data;
        default:
            return data
    }
}

export const sowApprovalReducer = (data = {response: false, backend_data: null }, action) => {
    switch (action.type) {
        case action_type.SET_SOW_APPROVAL_LIST:
            return action.data;
        default:
            return data
    }
}

export const timesheetApprovalReducer = (data = {response: false, backend_data: null }, action) => {
    switch (action.type) {
        case action_type.SET_TIMESHEET_APPROVAL_LIST:
            return action.data;
        default:
            return data
    }
}

export const invoiceApprovalReducer = (data = {response: false, backend_data: null }, action) => {
    switch (action.type) {
        case action_type.SET_INVOICE_APPROVAL_LIST:
            return action.data;
        default:
            return data
    }
}

export const dynamicVariableReducer = (data = {response: false, backend_data: null }, action) => {
    switch (action.type) {
        case action_type.SET_MSA_DYNAMIC_VARIABLES:
            return action.data;
        default:
            return data
    }
}
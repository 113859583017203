import * as action_type from '../../../../Store/Constant'

export const resourceProfileDataReducer = (data = {response: false, backend_data: null }, action) => {
    switch (action.type) {
        case action_type.SET_RESOURCE_PROFILE_LIST:
            return action.data;
        default:
            return data
    }
}

export const viewResProfileReducer = (data = {response: false, backend_data: null }, action) => {
    switch (action.type) {
        case action_type.SET_VIEW_RES_PROFILE:
            return action.data;
        default:
            return data
    }
}

export const loginUserDataReducer = (data = {response: false, backend_data: null }, action) => {
    switch (action.type) {
        case action_type.SET_LOGIN_USER_PROFILE_DETAILS:
            return action.data;
        default:
            return data
    }
}

export const viewAgnProfileReducer = (data = {response: false, backend_data: null }, action) => {
    switch (action.type) {
        case action_type.SET_VIEW_AGN_PROFILE:
            return action.data;
        default:
            return data
    }
}
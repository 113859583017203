import { put } from 'redux-saga/effects'
import * as action_type from '../../../../Store/Constant';
import { AUTH_MULTYPART_HEADERS, API_URL, AUTH_HEADERS, LIMIT_DATA, HEADERS } from '../../../../Axios/axiosData'
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';

export function* jobsFiltersData(payload) {

    let data = { response: false, backend_data: null }
    yield put({ type: action_type.SET_LOADING, payload: true })

    let url;

    url = API_URL + `job/public-job-post/?${payload?.payload}`

    // url = API_URL + `job/public-job-post/?limit=5&${payload?.payload}`

    // if (payload?.payload) {
    //     url = API_URL + `job/public-job-post/?limit=$5&${payload?.payload}`
    // } else {
    //     url = API_URL + `job/public-job-post/?limit=100`
    // }

    yield axios.get(url, AUTH_HEADERS())
        .then(resp => {

            data.response = true
            data.backend_data = resp.data

        }).catch((error) => {
            console.log(error, "error")
        })

    yield put({ type: action_type.SET_JOBS_FILTERS_DATA, data })
    yield put({ type: action_type.SET_LOADING, payload: false })
}

export function* latestsJobsData(payload) {

    let data = { response: false, backend_data: null }
    yield put({ type: action_type.SET_LOADING, payload: true })

    let url = API_URL + `job/public-job-post/relevant-jobs/?${payload?.payload}`

    yield axios.get(url, AUTH_HEADERS())
        .then(resp => {

            data.response = true
            data.backend_data = resp.data

        }).catch((error) => {
            console.log(error, "error")
        })

    yield put({ type: action_type.SET_LATEST_JOBS_DATA, data })
    yield put({ type: action_type.SET_LOADING, payload: false })
}


export function* profileFiltesData(payload) {

    let data = { response: false, backend_data: null }
    yield put({ type: action_type.SET_LOADING, payload: true })

    let url;

    url = API_URL + `resources/resources-profile/?${payload?.payload}`

    // ${LIMIT_DATA}

    // if (payload?.payload) {
    //     url = API_URL + `resources/resources-profile/?limit=100&${payload?.payload}`
    // } else {
    //     url = API_URL + `resources/resources-profile/?limit=100`
    // }

    yield axios.get(url, AUTH_HEADERS())
        .then(resp => {

            data.response = true
            data.backend_data = resp.data

        }).catch((error) => {
            console.log(error, "error")
        })

    yield put({ type: action_type.SET_PROFILES_FILTERS_DATA, data })
    yield put({ type: action_type.SET_LOADING, payload: false })
}

export function* applyForJobData(payload) {

    let data = { response: false, backend_data: null }
    yield put({ type: action_type.SET_LOADING, payload: true })

    yield axios.post(API_URL + `job/job-application/`, payload?.payload, AUTH_HEADERS())
        .then(resp => {

            data.response = true
            data.backend_data = resp.data

            if(resp?.data?.id){
                payload?.data(resp?.data?.id);
            }

            toast.success("Job Apply Successfully")

        }).catch((error) => {
            toast.error("Error : Job Not Apply")
        })

    yield put({ type: action_type.SET_PROFILES_FILTERS_DATA, data })
    yield put({ type: action_type.SET_LOADING, payload: false })
}

export function* updateApplyForJobData(payload) {

    let data = { response: false, backend_data: null }
    yield put({ type: action_type.SET_LOADING, payload: true })

    yield axios.patch(API_URL + `${payload?.payload}`, payload?.data, AUTH_MULTYPART_HEADERS())
        .then(resp => {
            data.response = true
            data.backend_data = resp.data

        }).catch((error) => {
            toast.error("Error")
        })

    yield put({ type: action_type.SET_PROFILES_FILTERS_DATA, data })
    yield put({ type: action_type.SET_LOADING, payload: false })
}
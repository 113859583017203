import * as action_type from '../../../../Store/Constant'

export const detailsMyTeamUserReducer = (data = {response: false, backend_data: null }, action) => {
    switch (action.type) {
        case action_type.SET_MY_TEAM_USER_DETAILS:
            return action.data;
        default:
            return data
    }
}

export const getContractResponseReducer = (data = {response: false, backend_data: null }, action) => {
    switch (action.type) {
        case action_type.SET_ANALYZE_CONTRACT:
            return action.data;
        default:
            return data
    }
}
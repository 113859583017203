import { put } from "redux-saga/effects";
import * as action_type from "../../../../Store/Constant";
import {
  AUTH_MULTYPART_HEADERS,
  API_URL,
  AUTH_HEADERS,
  LIMIT_DATA,
} from "../../../../Axios/axiosData";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";

export function* getInvoiceListData(payload) {
  let data = { response: false, backend_data: null };
  yield put({ type: action_type.SET_LOADING, payload: true });

  let url = "";

  if (payload?.payload) {
    url = API_URL + `invoice/invoice/?limit=${LIMIT_DATA}${payload?.payload}`;
  } else {
    url = API_URL + `invoice/invoice/?limit=100`;
  }

  yield axios
    .get(url, AUTH_HEADERS())
    .then((resp) => {
      data.response = true;
      data.backend_data = resp.data;
    })
    .catch((error) => {
      console.log(error, "error");
    });

  yield put({ type: action_type.SET_INVOICE_LIST, data });
  yield put({ type: action_type.SET_LOADING, payload: false });
}

export function* createNewInvoiceData(payload) {
  let data = { response: false, backend_data: null };
  yield put({ type: action_type.SET_LOADING, payload: true });

  yield axios
    .post(API_URL + `invoice/invoice/`, payload?.payload, AUTH_HEADERS())
    .then((resp) => {
      data.response = true;
      data.backend_data = resp.data;

      toast.success("Invoice Created Successfully");
    })
    .catch((error) => {
      if (error?.response?.data?.detail) {
        toast.error(`${"Detail"} : ${error?.response?.data?.detail[0]}`);
      }

      if (error?.response?.data?.date) {
        toast.error(`${"Date"} : ${error?.response?.data?.date[0]}`);
      }
    });

  yield axios
    .get(API_URL + `invoice/invoice/?limit=${LIMIT_DATA}`, AUTH_HEADERS())
    .then((resp) => {
      data.response = true;
      data.backend_data = resp.data;
    })
    .catch((error) => {
      console.log(error, "error");
    });

  yield put({ type: action_type.SET_INVOICE_LIST, data });
  yield put({ type: action_type.SET_LOADING, payload: false });
}

export function* getInvoiceChargesListData() {
  let data = { response: false, backend_data: null };
  yield put({ type: action_type.SET_LOADING, payload: true });

  yield axios
    .get(
      API_URL + `invoice/invoice-charges/?limit=${LIMIT_DATA}`,
      AUTH_HEADERS()
    )
    .then((resp) => {
      data.response = true;
      data.backend_data = resp.data;
    })
    .catch((error) => {
      console.log(error, "error");
    });

  yield put({ type: action_type.SET_INVOICE_CHARGES_LIST, data });
  yield put({ type: action_type.SET_LOADING, payload: false });
}

export function* addInvoiceChargesData(payload) {
  let data = { response: false, backend_data: null };
  yield put({ type: action_type.SET_LOADING, payload: true });

  yield axios
    .post(API_URL + `invoice/invoice-charges/`, payload.payload, AUTH_HEADERS())
    .then((resp) => {
      data.response = true;
      data.backend_data = resp.data;
    })
    .catch((error) => {
      if (error?.response?.data?.description) {
        toast.error(
          `${"Description"} : ${error?.response?.data?.description[0]}`
        );
      }

      if (error?.response?.data?.rate) {
        toast.error(`${"Rate"} : ${error?.response?.data?.rate[0]}`);
      }

      if (error?.response?.data?.tax_percentage) {
        toast.error(
          `${"Percentage"} : ${error?.response?.data?.tax_percentage[0]}`
        );
      }

      if (error?.response?.data?.total_amount) {
        toast.error(`${"Amount"} : ${error?.response?.data?.total_amount[0]}`);
      }
    });

  yield axios
    .get(
      API_URL + `invoice/invoice-charges/?limit=${LIMIT_DATA}`,
      AUTH_HEADERS()
    )
    .then((resp) => {
      data.response = true;
      data.backend_data = resp.data;
    })
    .catch((error) => {
      console.log(error, "error");
    });

  yield put({ type: action_type.SET_INVOICE_CHARGES_LIST, data });
  yield put({ type: action_type.SET_LOADING, payload: false });
}

export function* getInvoiceDatailsData(payload) {
  let data = { response: false, backend_data: null };
  yield put({ type: action_type.SET_LOADING, payload: true });

  yield axios
    .get(API_URL + `invoice/invoice/${payload?.payload}/`, AUTH_HEADERS())
    .then((resp) => {
      data.response = true;
      data.backend_data = resp.data;
    })
    .catch((error) => {
      console.log(error, "error");
    });

  yield put({ type: action_type.SET_INVOICE_DETAILS_DATA, data });
  yield put({ type: action_type.SET_LOADING, payload: false });
}

export function* resourceSendInvoiceMailData(payload){
  yield put({ type: action_type.SET_LOADING, payload: true });
  yield axios
    .get(API_URL + `invoice/invoice/${payload?.payload}/trigger_mail/`, AUTH_HEADERS())
    .then((resp) => {
      if(resp?.data?.message){
        toast.success(resp?.data?.message);
      }
    })
    .catch((err) => { });

  yield put({ type: action_type.SET_LOADING, payload: false });
}

export function* getPdfInvoiceData(payload) {

  let data = { response: false, backend_data: null };
  yield put({ type: action_type.SET_LOADING, payload: true });

  yield axios
    .get(API_URL + `invoice/invoice/${payload?.payload}/download_pdf/`, AUTH_HEADERS())
    .then((resp) => {
      data.response = true;
      data.backend_data = resp.data;
    })
    .catch((error) => {
      console.log(error, "error");
    });

  yield put({ type: action_type.SET_INVOICE_PDF_DATA, data });
  yield put({ type: action_type.SET_LOADING, payload: false });
}

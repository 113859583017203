import { put } from "redux-saga/effects";
import * as action_type from "../../../../Store/Constant";
import {
  AUTH_MULTYPART_HEADERS,
  API_URL,
  AUTH_HEADERS,
  LIMIT_DATA,
} from "../../../../Axios/axiosData";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";

export function* paymentsData(payload) {
  let data = { response: false, backend_data: null };
  yield put({ type: action_type.SET_LOADING, payload: true });

  yield axios
    .post(API_URL + `invoice/payment/`, payload?.payload, AUTH_MULTYPART_HEADERS())
    .then((resp) => {
      data.response = true;
      data.backend_data = resp.data;

      toast.success("Payments Details Saved Successfully");

      if(payload?.data){
        setTimeout(() => {
          payload?.data();
        }, 3000);
      }
    })
    .catch((error) => {
      if (error?.response?.data?.detail) {
        toast.error(`${"Detail"} : ${error?.response?.data?.detail[0]}`);
      }

      if (error?.response?.data?.date) {
        toast.error(`${"Date"} : ${error?.response?.data?.date[0]}`);
      }
    });

  yield put({ type: action_type.SET_LOADING, payload: false });
}

export function* paymentsInvoiceDetailsData(payload) {
  let data = { response: false, backend_data: null };
  yield put({ type: action_type.SET_LOADING, payload: true });

  yield axios
    .get(API_URL + `invoice/payment/${payload?.payload}/`, AUTH_HEADERS())
    .then((resp) => {
      data.response = true;
      data.backend_data = resp.data;
    })
    .catch((error) => {
      console.log(error, "error");
    });

  yield put({ type: action_type.SET_PAYMENTS_INVOICE_DETAILS, data });
  yield put({ type: action_type.SET_LOADING, payload: false });
}

export function* costOfPaymentsTransferData(payload) {
  let data = { response: false, backend_data: null };
  yield put({ type: action_type.SET_LOADING, payload: true });

  let url = ``

  if(payload?.payload){
    url = API_URL + `settings/payment-transfer-cost/${payload?.payload}/`
  }else{
    url = API_URL + `settings/payment-transfer-cost/`
  }

  yield axios
    .get(url, AUTH_HEADERS())
    .then((resp) => {
      data.response = true;
      data.backend_data = resp.data;
    })
    .catch((error) => {
      console.log(error, "error");
    });

  yield put({ type: action_type.SET_COST_OF_PAYMENTS_TRANSFER, data });
  yield put({ type: action_type.SET_LOADING, payload: false });
}
import * as action_type from '../../../../Store/Constant';

export const msaFieldReducer = (data = { loading: false}, action) => {
    switch (action.type) {
        case action_type.SET_MSA_FIELD:
            return action.data
        default:
            return data
    }
}

export const approvalAllUsersReducer = (data = { loading: false}, action) => {
    switch (action.type) {
        case action_type.SET_APPROVAL_ALL_USERS:
            return action.data
        default:
            return data
    }
}

export const resourceListReducer = (data = {response: false, backend_data: null }, action) => {
    switch (action.type) {
        case action_type.SET_RESOURCELIST:
            return action.data
        default:
            return data
    }
}


export const addMsaReducer = (data = { loading: false }, action) => {
    switch (action.type) {
        case action_type.SET_ADDMSA:
            return action.data
        default:
            return data
    }
}

export const agencyProfileListReducer = (data = {
    response: false, backend_data: null 
 }, action) => {
    switch (action.type) {
        case action_type.SET_AGENCY_PROFILE_LIST:
            return action.data
        default:
            return data
    }
}







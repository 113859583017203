import * as action_type from "../../../../Store/Constant";

export const paymentInvoiceDetailsReducer = (data = {response: false, backend_data: null }, action) => {
  switch (action.type) {
    case action_type.SET_PAYMENTS_INVOICE_DETAILS:
      return action.data;
    default:
      return data;
  }
};

export const costOfPaymentTransferReducer = (data = {response: false, backend_data: null }, action) => {
  switch (action.type) {
    case action_type.SET_COST_OF_PAYMENTS_TRANSFER:
      return action.data;
    default:
      return data;
  }
};

import * as action_type from '../../../../Store/Constant'

export const getTimesheetListDataReducer = (data = {response: false, backend_data: null }, action) => {
    switch (action.type) {
        case action_type.SET_TIMESHEET_LIST:
            return action.data;
        default:
            return data
    }
}

export const viewTimesheetDetailsReducer = (data = {response: false, backend_data: null }, action) => {
    switch (action.type) {
        case action_type.SET_VIEW_TIMESHEET_DETAILS:
            return action.data;
        default:
            return data
    }
}
import { put } from 'redux-saga/effects'
import * as action_type from '../../../../Store/Constant';
import { AUTH_MULTYPART_HEADERS, API_URL, AUTH_HEADERS, LIMIT_DATA } from '../../../../Axios/axiosData'
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';

export function* getTimesheetListData(payload) {

    let data = { response: false, backend_data: null }
    yield put({ type: action_type.SET_LOADING, payload: true })

    let url;

    if (payload?.payload) {
        url = API_URL + `timesheet/timesheet/?limit=${LIMIT_DATA}&${payload?.payload}`
    } else {
        url = API_URL + `timesheet/timesheet/?limit=100`
    }

    yield axios.get(url, AUTH_MULTYPART_HEADERS())
        .then(resp => {

            data.response = true
            data.backend_data = resp.data

        }).catch((error) => {
            console.log(error, "error")
        })

    yield put({ type: action_type.SET_TIMESHEET_LIST, data })
    yield put({ type: action_type.SET_LOADING, payload: false })

}

export function* createTimesheetData(payload) {

    let data = { response: false, backend_data: null }
    yield put({ type: action_type.SET_LOADING, payload: true })

    yield axios.post(API_URL + "timesheet/timesheet/", payload?.payload, AUTH_HEADERS())
        .then(resp => {

            data.response = true
            data.backend_data = resp.data

            toast.success("Timesheet Create Successfully")
            if(data?.response){
                payload.data();
            }

        }).catch((error) => {
            console.log("error", error)

            if(error?.response?.data?.message){
                toast.error(`${error?.response?.data?.message}`)
            }
        })

    yield axios.get(API_URL + `timesheet/timesheet/?limit=${LIMIT_DATA}`, AUTH_MULTYPART_HEADERS())
        .then(resp => {
            data.response = true
            data.backend_data = resp.data

        }).catch((error) => {
            console.log(error, "error")
        })

    yield put({ type: action_type.SET_TIMESHEET_LIST, data })
    yield put({ type: action_type.SET_LOADING, payload: false })

}

export function* viewTimesheetData({ payload }) {

    let data = { response: false, backend_data: null }
    yield put({ type: action_type.SET_LOADING, payload: true })

    yield axios.get(API_URL + `timesheet/timesheet/${payload}/`, AUTH_HEADERS())
        .then(resp => {
            data.response = true
            data.backend_data = resp.data

        }).catch((error) => {
            console.log(error, "error")
        })

    yield put({ type: action_type.SET_VIEW_TIMESHEET_DETAILS, data })
    yield put({ type: action_type.SET_LOADING, payload: false })

}
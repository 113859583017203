import React from 'react';
import LoadingScreen from "react-loading-screen";

function Loader() {
  return (
    <div> <LoadingScreen
             loading={true}
             bgColor="rgba(255,255,255,0.8)"
             spinnerColor="#686EFF"
             textColor="#686EFF !important"
             logoSrc=""
             text="Loading..."
           >
             {" "}
           </LoadingScreen></div>
  )
}

export default Loader;
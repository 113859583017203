import { combineReducers } from "redux";

import { resInitialProfileDataReducer } from "../Components/Dashboard/Resource/Redux/resourceReducer";
import {
  detailsMyTeamUserReducer,
  getContractResponseReducer,
} from "../Components/Dashboard/Organisation/Redux/inviteResourceReducer";
import {
  msaFieldReducer,
  addMsaReducer,
  resourceListReducer,
  approvalAllUsersReducer,
} from "../Components/Dashboard/Organisation/Redux/MsaReducer";

import {
  forgotSendEmailReducer,
  forgotVerifyOTPReducer,
} from "../Components/Dashboard/Organisation/Redux/forgotReducer";

import {
  msaListExcelReducer,
  sowListExcelReducer,
  timesheetListExcelReducer,
  invoiceListExcelReducer,
  roleListExcelReducer,
  usersListExcelReducer,
} from "../Components/Dashboard/Organisation/Redux/ExcelReducer";

import {
  signupReducer,
  navigateReducer,
  countryReducer,
  loadingReducer,
  skillsReducer,
  currencyDataReducer,
} from "../Components/Home/Redux/CheckLoginReducer";

import {
  singleUserRatingsReducer,
  getDetailsEditReveiwReducer,
} from "../Components/Dashboard/Organisation/Redux/RatingsReducer";

import {
  showListReducer,
  allMsaListReducer,
  retriveMsaReducer,
  sowDetailReducer,
  sowFieldReducer,
  allMsaFiltersListReducer,
  allOrganisationListReducer,
  OrganisationDetailsReducer,
} from "../Components/Dashboard/Organisation/Redux/organisationReducer";

import {
  companyLocationListReducer,
  businessUnitListReducer,
  costCenterListReducer,
  unpscCodeListReducer,
  taxGrouptListReducer,
  serviceTaxListReducer,
  glAccountListReducer,
  paymentTermsListReducer,
  msaTypeListReducer,
  sowTypeListReducer,
  expCategoryListReducer,
  incomeTaxslabsListReducer,
  privacySettingsListReducer,
} from "../Components/Dashboard/Organisation/Redux/SettingReducer";

import {
  organisationUserListReducer,
  agencyUserListReducer,
  orgUserDetailsReducer,
  agnUserDetailsReducer,
} from "../Components/Dashboard/Organisation/Redux/UserReducer";
import {
  msaApprovalReducer,
  sowApprovalReducer,
  timesheetApprovalReducer,
  invoiceApprovalReducer,
  dynamicVariableReducer,
} from "../Components/Dashboard/Organisation/Redux/ApprovalReducer";

import { agencyProfileListReducer } from "../Components/Dashboard/Organisation/Redux/MsaReducer";

import {
  orgRoleListReducer,
  orgRoleDetailReducer,
} from "../Components/Dashboard/Organisation/Redux/RoleReducer";

import {
  resourceProfileDataReducer,
  viewResProfileReducer,
  loginUserDataReducer,
  viewAgnProfileReducer,
} from "../Components/Dashboard/Organisation/Redux/ResProReducer";

import {
  getTimesheetListDataReducer,
  viewTimesheetDetailsReducer,
} from "../Components/Dashboard/Organisation/Redux/TimeSheetReducer";

import {
  getInvoiceDataReducer,
  getInvoiceChargesDataReducer,
  getInvoiceDetailsReducer,
  getPdfInvoiceReducer,
} from "../Components/Dashboard/Organisation/Redux/InvoiceReducer";

import {
  orgDashboardDataReducer,
  agnDashboardDataReducer,
  resDashboardDataReducer,
  expiringSowMsaDataReducer,
  revenueExpenseDataReducer,
  agencyStatisticDataReducer,
  totalSpendDataReducer,
  resourceEarningsDataReducer,
} from "../Components/Dashboard/Organisation/Redux/DashboardReducer";

import {
  allMsaAgreementTemplateReducer,
  msaAgreementTemplateByIdReducer,
  msaFormattedTemplateReducer,
  msaDefaultTemplateReducer,
  uploadMsaAgreementMediaReducer,
  MsaPdfDataReducer,
  docTypeContentDataReducer,
  SowPdfDataReducer,
  sowFormattedTemplateReducer,
  allSowListDataReducer,
} from "./agreementTemplate/agreementTemplateReducer";

import {
  jobPostDataReducer,
  languageListReducer,
  jobPostDetailsReducer,
  applicationListReducer,
  experienceListReducer,
} from "../Components/Dashboard/Organisation/Redux/JobPostReducer";

import {
  workAvailabilityReducer,
  userProfileDetailsReducer,
} from "../Components/Dashboard/Organisation/Redux/AvailabilityReducer";

import {
  jobsfilterDataReducer,
  latestJobsDataReducer,
  profilesfilterDataReducer,
} from "../Components/Dashboard/Organisation/Redux/jobProfileReducer";

import {
  paymentInvoiceDetailsReducer,
  costOfPaymentTransferReducer,
} from "../Components/Dashboard/Organisation/Redux/PaymentReducer";
import { commonDataReducer } from "./CommonReducer";


export default combineReducers({
  signupReducer,
  msaFieldReducer,
  addMsaReducer,
  resourceListReducer,
  navigateReducer,
  loadingReducer,
  countryReducer,
  skillsReducer,
  showListReducer,
  sowDetailReducer,
  sowFieldReducer,
  allMsaListReducer,
  allOrganisationListReducer,
  OrganisationDetailsReducer,
  agencyProfileListReducer,
  companyLocationListReducer,
  businessUnitListReducer,
  costCenterListReducer,
  unpscCodeListReducer,
  taxGrouptListReducer,
  serviceTaxListReducer,
  glAccountListReducer,
  paymentTermsListReducer,
  msaTypeListReducer,
  sowTypeListReducer,
  organisationUserListReducer,
  orgRoleListReducer,
  orgRoleDetailReducer,
  retriveMsaReducer,
  msaApprovalReducer,
  sowApprovalReducer,
  timesheetApprovalReducer,
  invoiceApprovalReducer,
  resourceProfileDataReducer,
  viewResProfileReducer,
  loginUserDataReducer,
  viewAgnProfileReducer,
  getTimesheetListDataReducer,
  viewTimesheetDetailsReducer,
  dynamicVariableReducer,
  allMsaAgreementTemplateReducer,
  msaAgreementTemplateByIdReducer,
  msaFormattedTemplateReducer,
  msaDefaultTemplateReducer,
  getInvoiceDataReducer,
  getInvoiceChargesDataReducer,
  getInvoiceDetailsReducer,
  agencyUserListReducer,
  jobPostDataReducer,
  languageListReducer,
  jobPostDetailsReducer,
  orgUserDetailsReducer,
  agnUserDetailsReducer,
  orgDashboardDataReducer,
  agnDashboardDataReducer,
  resDashboardDataReducer,
  workAvailabilityReducer,
  userProfileDetailsReducer,
  resInitialProfileDataReducer,
  jobsfilterDataReducer,
  latestJobsDataReducer,
  profilesfilterDataReducer,
  applicationListReducer,
  allMsaFiltersListReducer,
  experienceListReducer,
  expCategoryListReducer,
  uploadMsaAgreementMediaReducer,
  MsaPdfDataReducer,
  docTypeContentDataReducer,
  sowFormattedTemplateReducer,
  allSowListDataReducer,
  SowPdfDataReducer,
  approvalAllUsersReducer,
  currencyDataReducer,
  getPdfInvoiceReducer,
  forgotSendEmailReducer,
  forgotVerifyOTPReducer,
  incomeTaxslabsListReducer,
  privacySettingsListReducer,
  paymentInvoiceDetailsReducer,
  costOfPaymentTransferReducer,
  singleUserRatingsReducer,
  getDetailsEditReveiwReducer,
  msaListExcelReducer,
  sowListExcelReducer,
  timesheetListExcelReducer,
  invoiceListExcelReducer,
  roleListExcelReducer,
  usersListExcelReducer,
  detailsMyTeamUserReducer,
  getContractResponseReducer,
  expiringSowMsaDataReducer,
  totalSpendDataReducer,
  revenueExpenseDataReducer,
  agencyStatisticDataReducer,
  resourceEarningsDataReducer,
  commonDataReducer,
});

import * as action_type from '../../../../Store/Constant';

export const orgRoleListReducer = (data = { loading: false }, action) => {
    switch (action.type) {
        case action_type.SET_ORG_ROLE_LIST:
            return action.data
        default:
            return data
    }
}

export const orgRoleDetailReducer = (data = { role: null }, action) => {
    switch (action.type) {
        case action_type.SET_ORG_ROLE_DETAIL:
            return action.data
        case action_type.UPDATE_ORG_ROLE_DETAIL:
            let new_data = {role:data.role}

            let {val, field, model} = action
            if (model === null){
                new_data.role[field] = val
            }
            else{
                new_data.role.permissions[model][field] = val
            }

            return new_data 
        default:
            return data
    }
}


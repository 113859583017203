import * as action_type from '../Constant'

export const allMsaAgreementTemplateReducer = (data = { loading: false }, action) => {
    switch (action.type) {
        case action_type.SET_ALL_MSA_AGREEMENT_TEMPLATE:
            return action.data
        default:
            return data
    }
}


export const msaAgreementTemplateByIdReducer = (data = {response: false, backend_data: null}, action) => {
    switch (action.type) {
        case action_type.SET_MSA_AGREEMENT_TEMPLATE_BY_ID:
            return action.data
        default:
            return data
    }
}

export const msaFormattedTemplateReducer = (data = { loading: false }, action) => {
    switch (action.type) {
        case action_type.SET_FORMATTED_TEMPLATE:
            return action.data
        default:
            return data
    }
}


export const msaDefaultTemplateReducer = (data = {response: false, backend_data: null}, action) => {
    switch (action.type) {
        case action_type.SET_DEFAULT_TEMPLATE:
            return action.data
        default:
            return data
    }
}

export const sendMsaAgreementTemplateReducer = (data = {response: false, backend_data: null}, action) => {
    switch (action.type) {
        case action_type.SEND_MSA_AGREEMENT_TEMPLATE:
            return action.data
        default:
            return data
    }
}

export const uploadMsaAgreementMediaReducer = (data = {response: false, backend_data: null}, action) => {
    switch (action.type) {
        case action_type.SET_MSA_AGREEMENT_MEDIA:
            return action.data
        default:
            return data
    }
}

export const MsaPdfDataReducer = (data = {response: false, backend_data: null}, action) => {
    switch (action.type) {
        case action_type.SET_DOWNLOAD_MSA_PDF_DATA:
            return action.data
        default:
            return data
    }
}

export const docTypeContentDataReducer = (data = {response: false, backend_data: null}, action) => {
    switch (action.type) {
        case action_type.SET_DOC_TYPE_CONTENT:
            return action.data
        default:
            return data
    }
}

export const SowPdfDataReducer = (data = {response: false, backend_data: null}, action) => {
    switch (action.type) {
        case action_type.SET_DOWNLOAD_SOW_PDF_DATA:
            return action.data
        default:
            return data
    }
}

// sow template reducer

export const sowFormattedTemplateReducer = (data = { loading: false }, action) => {
    switch (action.type) {
        case action_type.SET_SOW_FORMATTED_TEMPLATE:
            return action.data
        default:
            return data
    }
}

export const allSowListDataReducer = (data = { loading: false }, action) => {
    switch (action.type) {
        case action_type.SET_ALL_SOW_TEMPLATE:
            return action.data
        default:
            return data
    }
}
import * as action_type from '../../../../Store/Constant'

export const getInvoiceDataReducer = (data = {response: false, backend_data: null }, action) => {
    switch (action.type) {
        case action_type.SET_INVOICE_LIST:
            return action.data;
        default:
            return data
    }
}

export const getInvoiceChargesDataReducer = (data = {response: false, backend_data: null }, action) => {
    switch (action.type) {
        case action_type.SET_INVOICE_CHARGES_LIST:
            return action.data;
        default:
            return data
    }
}

export const getInvoiceDetailsReducer = (data = {response: false, backend_data: null }, action) => {
    switch (action.type) {
        case action_type.SET_INVOICE_DETAILS_DATA:
            return action.data;
        default:
            return data
    }
}

export const getPdfInvoiceReducer = (data = {response: false, backend_data: null }, action) => {
    switch (action.type) {
        case action_type.SET_INVOICE_PDF_DATA:
            return action.data;
        default:
            return data
    }
}
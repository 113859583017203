export const GOOGLR_URL = "https://oauth2.googleapis.com/token"
export const GOOGLE_CLIENT_ID = "623021744642-jdl436miod64hkef748voisfejshh3g2.apps.googleusercontent.com"
export const GOOGLE_SECRECT_ID = "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"

// export const GOOGLR_URL = process.env.REACT_APP_GOOGLR_URL
// export const GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID
// export const GOOGLE_SECRECT_ID = process.env.REACT_APP_GOOGLE_SECRECT_ID

export const ORG_URL = `https://accounts.google.com/o/oauth2/v2/auth?redirect_uri=https://app.agreementpaper.com/signup_enterprise/organisation&prompt=consent&response_type=code&client_id=${GOOGLE_CLIENT_ID}&scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.email+https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.profile&access_type=offline`
export const AGENCY_URL = `https://accounts.google.com/o/oauth2/v2/auth?redirect_uri=https://app.agreementpaper.com/signup_talent/agency&prompt=consent&response_type=code&client_id=${GOOGLE_CLIENT_ID}&scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.email+https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.profile&access_type=offline`
export const RES_URL = `https://accounts.google.com/o/oauth2/v2/auth?redirect_uri=https://app.agreementpaper.com/signup_recruitment/resource&prompt=consent&response_type=code&client_id=${GOOGLE_CLIENT_ID}&scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.email+https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.profile&access_type=offline`
export const LOGIN_URL = `https://accounts.google.com/o/oauth2/v2/auth?redirect_uri=https://app.agreementpaper.com/login&prompt=consent&response_type=code&client_id=${GOOGLE_CLIENT_ID}&scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.email+https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.profile&access_type=offline`